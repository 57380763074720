import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip";

class ContactPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact" description="Contact our friendly and experienced sales team, available to answer your queries on weekdays 8AM-5PM and weekends 8AM-12PM." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent headblock">
            <h1 className="headblock__heading">Contact</h1>
            <p className="headblock__byline">How to get in touch with Tetley's Coaches</p>
          </div>
          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2 className="contact-subheading">Sales &amp; enquiries</h2>
                <p>Our friendly and experienced sales team is available to answer your queries on weekdays 8AM-5PM and weekends 8AM-12PM.</p>
                <ul style={{ marginBottom: 40 }}>
                  <li style={{ margin: 0 }}>Phone: <a className="tel" href="tel:01132762276">0113 276 2276</a></li>
                  <li style={{ margin: 0 }}>Email: <a href="mailto:sales@tetleyscoaches.co.uk">sales@tetleyscoaches.co.uk</a></li>
                </ul>

                <h2 className="contact-subheading">Accounts</h2>
                <p>For account related enquiries please email <a href="mailto:accounts@tetleyscoaches.co.uk">accounts@tetleyscoaches.co.uk</a>.</p>

                <h2 className="contact-subheading">Emergency contact</h2>
                <p>Please note this number is available 24/7 and for use in case of an emergency only.</p>
                <p>If your query can wait until office hours, please call back using the above opening times, or email <a href="mailto:sales@tetleyscoaches.co.uk">sales@tetleyscoaches.co.uk</a>.</p>
                <p>Emergency phone: <a className="tel" href="tel:01132762276">0113 276 2276</a></p>



              </div>
              <div className="page-content__text">
                <h2 className="contact-subheading">Address</h2>
                <p>Tetley's Motor Services Limited<br />
                76 Goodman Street<br />
                Leeds<br />
                LS10 1NY</p>
                <iframe title="Tetley's coaches" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2357.2954453257644!2d-1.5276730482601504!3d53.784228979975246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795c38882c4475%3A0x9fc9a7dfa886a150!2sTetley&#39;s%20Motor%20Services%20Limited!5e0!3m2!1sen!2suk!4v1571230110996!5m2!1sen!2suk" width="100%" height="350" frameborder="0" style={{ border:0 }} allowfullscreen=""></iframe>

              </div>
            </div>

          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ContactPage
